<template>
  <div style="padding: 20px">
    <el-input style="width: 200px" v-model="query.coachName" placeholder="教练姓名"
              class="handle-input margin10"></el-input>
    <el-cascader
        v-model="choiceDeptList"
        placeholder="请选择区域"
        :options="deptList"
        collapse-tags
        :collapse-tags-tooltip="true"
        clearable
        :props="{ multiple: true,value: 'id',label: 'name',children: 'children'}"/>
    <el-date-picker
        :clearable="false"
        style="margin-left: 10px"
        v-model="choiceDate"
        type="month"
        placeholder="请选择月份"
    />
    <el-button
        type="primary"
        icon="Search"
        style="margin-left: 10px"
        @click="selectData()"
    >搜索
    </el-button>
    <el-button type="primary" icon="Search" @click="importFile=true">导入</el-button>
    <el-popconfirm @confirm="exportFile" title="确定要导出吗？">
      <template #reference>
        <el-button
            type="primary"
            icon="Download"
            style="margin-left: 10px">导出
        </el-button>
      </template>
    </el-popconfirm>
    <el-dialog v-model="importFile" width="700px">
      <div style="display: flex;justify-content: center">
        <el-upload drag limit="1" :auto-upload="false" :on-remove="handleRemove"
                   :on-change="fileChange" :on-exceed="exceedFile" :file-list="fileList">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <div style="margin:30px 0 0 20px">
        <a href="https://codeimg.yanhuotiyu.com/exportExcel/2023/9/15/TargetExcelExport.xlsx">下载导入模板！</a>
      </div>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="importFiles">导入</el-button>
      </span>
      </template>

    </el-dialog>
    <div class="infinite-list" v-infinite-scroll="onReachBottom">
      <div class="items">
        <div v-for="(item,index) in tableData" :key="index">
          <div class="item">
            <div style="font-size: 15px;font-weight: bold;text-align: center;margin-bottom: 10px">{{ item.coachName }}
            </div>
            <div style="font-size: 14px;margin: 10px 0">目标：
              <span style="font-weight: bold">{{ item.targetNumber }}</span>
              <el-popover :visible="item.updateDialog" placement="top" :width="160">
                <el-input
                    v-model="item.targetNumber"
                    class="w-50 m-2"
                    size="small"
                    type="number"
                    placeholder="请输入新的目标"
                />
                <div style="text-align: center;margin-top: 20px">
                  <el-button size="mini" text @click="item.updateDialog = false">取消</el-button>
                  <el-button size="mini" type="primary" @click="item.updateDialog = false,updateTarget(item)">确定
                  </el-button>
                </div>
                <template #reference>
                  <el-button @click="item.updateDialog = true" text icon="EditPen" style="color:#409EFF;font-weight: bold" circle />
                </template>
              </el-popover>
            </div>
            <div style="font-size: 14px;margin: 10px 0">校区：{{ item.areaName }}</div>
            <el-tooltip
                content="个人目标"
                placement="top"
                effect="dark"
            >
              <div style="display:flex;">
                <div style="width: 90%">
                  <el-progress
                      :text-inside="true"
                      :percentage="getPercent(item.accomplishTargetNumber,item.targetNumber) > 100 ? 100 :getPercent(item.accomplishTargetNumber,item.targetNumber)"
                      :stroke-width="18">
                    <span>{{ item.accomplishTargetNumber == null ? 0 : item.accomplishTargetNumber }}</span>
                  </el-progress>
                </div>
                <div style="font-size: 14px;margin-left: 10px;font-weight: bold">
                  {{ getPercent(item.accomplishTargetNumber, item.targetNumber) }}%
                </div>
              </div>
            </el-tooltip>
            <el-tooltip
                v-if="item.areaTarget!=null"
                content="区域目标"
                placement="top"
                effect="dark"
            >
              <div style="display:flex;margin-top: 10px">
                <div style="width: 90%">
                  <el-progress
                      status="success"
                      :text-inside="true"
                      :percentage="getPercent(item.areaTarget.areaAccomplishTargetNumber,item.areaTarget.areaTargetNumber) > 100 ?
                      100 : getPercent(item.areaTarget.areaAccomplishTargetNumber,item.areaTarget.areaTargetNumber)"
                      :stroke-width="18">
                    <span>{{
                        item.areaTarget.areaAccomplishTargetNumber == null ? 0 : item.areaTarget.areaAccomplishTargetNumber
                      }}</span>
                  </el-progress>
                </div>
                <div style="font-size: 14px;margin-left: 10px;font-weight: bold">
                  {{ getPercent(item.areaTarget.areaAccomplishTargetNumber, item.areaTarget.areaTargetNumber) }}%
                </div>
              </div>
            </el-tooltip>
          </div>
        </div>
        <div style="width: 250px;padding: 0 20px;"></div>
        <div style="width: 250px;padding: 0 20px;"></div>
        <div style="width: 250px;padding: 0 20px;"></div>
        <div style="width: 250px;padding: 0 20px;"></div>
      </div>
      <div v-if="query.pageIndex>2 && noData == false" v-loading="tableLoading == true && skeleton == false"
           element-loading-text="加载中，请稍等..."
           style="height: 300px;">
      </div>
      <div v-if="noData == true">
        <div v-if="total>0"
             style="text-align: center;height: 100px;font-weight: bold;color:#909399;font-size: 14px;padding-top: 80px">
          ----- 到底啦 -----
        </div>
        <el-empty style="margin-top: 150px" v-else description="没有你需要的数据！"/>

      </div>

      <div v-if="skeleton" class="items">
        <div v-for=" (item,index) in 20" :key="index" style="text-align: center" class="item">
          <el-skeleton :rows="0" animated/>
          <div style="text-align: left;margin: 10px 0">
            <el-skeleton-item variant="text" style="width: 40%;"/>
          </div>
          <el-skeleton-item variant="text" style="margin-right: 16px"/>
          <div style="display: flex;align-items: center;margin-top: 10px">
            <el-skeleton-item variant="text" style="margin-right: 16px"/>
            <el-skeleton-item variant="text" style="width: 30%"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {listXcxDept} from "@/api/dept";
import {selectList, updateTarget, exportExcel, uploadPerformanceTarget} from "@/api/performanceTarget";

export default {
  name: "performanceTarget",
  data() {
    return {
      importFile: false,
      fileList: [],
      choiceDate: new Date(),
      deptList: [],
      choiceDeptList: [],
      tableData: [],
      total: 0,
      skeleton: true,
      tableLoading: false,
      noData: false,
      updateDialog: false,
      query: {
        coachName: "",
        deptIdList: [],
        year: null,
        month: null,
        pageIndex: 0,
        pageSize: 25
      }
    }
  },
  methods: {
    exceedFile() {
      this.$message.warning(`只能选择1个文件，请先移除后重新尝试。`);
    },
    // 文件状态改变时的钩子
    fileChange(file) {
      let extension = file.name.substring(file.name.lastIndexOf('.') + 1);
      let size = file.size / 1024 / 1024;
      if (extension !== 'xlsx') {
        this.fileList = []
        this.$message.warning('只能上传后缀是.xlsx的文件');
      }
      if (size > 10) {
        this.fileList = []
        this.$message.warning('文件大小不得超过100M');
      }
      if (extension == 'xlsx' && size < 10) {
        this.fileList.push(file.raw);
      }
    },
    handleRemove() {
      this.fileList = []
    },
    /**
     * 导入数据
     */
    importFiles() {
      if (this.fileList.length === 0) {
        this.$message.warning('请上传文件');
      } else {
        let form = new FormData();
        form.append("file", this.fileList[0]);
        uploadPerformanceTarget(form).then(val => {
          if (val.code == 200) {
            this.$message.success('导入成功');
            this.fileList = []
          } else {
            this.$message.warning('导入失败,');
          }
        })
      }
    },
    async onReachBottom(){
      if (this.tableData.length >= 25){
        await this.getData();
      }
      console.log("触底")
    },
    async selectData() {
      this.skeleton = true;
      this.noData = false;
      this.tableData = [];
      this.query.pageIndex = 0;
      await this.getData();
    },
    /**
     * 更新业绩目标
     */
    updateTarget(item) {
      updateTarget(item).then(val => {
        if (val.code === 200) {
          this.$message.success('更新成功！')
        } else {
          this.$message.error('更新失败！')
        }
      })
    },
    /**
     * 计算num占total的百分百多少
     * @param num 实际完成
     * @param total 中目标
     * @returns {number}
     */
    getPercent(num, total) {
      if ((num == null || total == null) || (num == 0 || total == 0)) {
        return 0;
      }
      return (Math.round(num / total * 10000) / 100.00);// 小数点后两位百分比
    },
    /**
     * 部门数据递归处理无效空数组
     * @param commodityType
     * @returns {*}
     */
    getTypeList(commodityType) {
      if (commodityType != null) {
        commodityType.forEach(items => {
          if (items != null) {
            if (items.children != null && items.children.length > 0) {
              this.getTypeList(items.children);
            } else {
              items.children = undefined;
            }
          }
        });
        return commodityType;
      }
    },
    /**
     * 获取列表信息
     */
    getData: async function () {
      console.log("CJL TES  ---getData run");
      if (this.choiceDate != null) {
        let list = this.getYearAndMonth(this.choiceDate);
        this.query.year = list[0];
        this.query.month = list[1];
      } else {
        this.query.year = null;
        this.query.month = null;
      }
      this.tableLoading = true;
      this.query.pageIndex = this.query.pageIndex + 1;
      this.query.deptIdList = [];
      this.choiceDeptList.forEach((item) => {
        this.query.deptIdList.push(item[item.length - 1])
      })
      console.log("CJL TES  ---begin selectList run");
      await selectList(this.query).then(res => {
        this.tableLoading = false;
        this.skeleton = false;
        this.tableData.push(...res.data.records);
        this.total = res.data.total;
        if (this.tableData.length === this.total) {
          this.noData = true;
        }
      })
    },
    /**
     * 导出
     */
    exportFile() {
      exportExcel(this.query)
    },

    getYearAndMonth(val) {
      let date = new Date(val)
      let month = date.getMonth() + 1;
      month = month < 10 ? ('0' + month) : month;
      return [date.getFullYear(), month]
    },
  },
  created() {
    console.log("CJL TES  ---created run");
    this.selectData();
    listXcxDept().then(res => {
      this.deptList = this.getTypeList(res.data);
    })
  },
}
</script>

<style scoped>
.margin10 {
  margin: 10px;
}

.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.item {
  margin-bottom: 20px;
  margin-top: 10px;
  width: 250px;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0px 1px 10px 1px #f5f5f5;
}

.item:hover {
  box-shadow: 0px 1px 20px 1px #e2e2e2;
}

.infinite-list {
  margin-top: 20px;
  height: 700px;
  overflow: auto
}
</style>